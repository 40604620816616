import { Injectable, OnInit } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(
    private readonly layoutService: VexLayoutService,
    private authService: AuthenticationService
  ) {

    let user: any = this.authService.getCurrentUser()
    if (user) {
      if (user.roles[0].name.toLowerCase() == 'administrador') {
        this.loadNavigation();
      } else if (user.roles[0].name.toLowerCase() == 'usuario') {
        this.loadNavigationUser();
      }
      
    }
    
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'dropdown',
        label: 'Buscador',
        children: [
          {
            type: 'link',
            label: 'Geográfico',
            route: 'geosearch',
            icon: 'mat:gps_fixed',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'General',
            route: 'generalsearch',
            icon: 'mat:search',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'link',
        label: 'Descarga',
        route: 'download',
        icon: '',
        routerLinkActiveOptions: { exact: true }        
      },
      {
        type: 'link',
        label: 'Usuarios',
        route: 'users',
        icon: '',
        routerLinkActiveOptions: { exact: true }        
      },
      {
        type: 'link',
        label: 'Productos',
        route: 'product',
        icon: '',
        routerLinkActiveOptions: { exact: true }        
      }
    ]);
  }

  loadNavigationUser(): void {
    this._items.next([
      {
        type: 'dropdown',
        label: 'Buscador',
        children: [
          {
            type: 'link',
            label: 'Geográfico',
            route: 'geosearch',
            icon: 'mat:gps_fixed',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'General',
            route: 'generalsearch',
            icon: 'mat:search',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      }
    ]);
  }
}

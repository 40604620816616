//import { NgxLoggerLevel } from 'ngx-logger';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,  
  //apiUrl: 'https://gustavargas.ddns.net:55447',
  //apiUrl: 'http://gustavargas.ddns.net:3001'
  //apiUrl: 'http://gusvargas.duckdns.org:32000'
  //apiUrl: 'https://api.stock-master.net',
  apiUrl: 'https://api-stockmaster.blackcode.cl',
  //apiUrl: 'https://api-dev.stock-master.net',  
  //logLevel: NgxLoggerLevel.TRACE,
  //serverLogLevel: NgxLoggerLevel.OFF,
};

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
//import 'rxjs/add/operator/delay';

import { environment } from '../../../environments/environment';
import { of, EMPTY } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends ApiService{

    constructor(http: HttpClient,
        @Inject('LOCALSTORAGE') private localStorage: Storage) {
            super(`${environment.apiUrl}`, http);
    }

    login(email: string, password: string) {
     
        const formData = {
            username: email,
            password: password
        }
        return this.postUrl('/auth/login', JSON.stringify(formData));      

    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.localStorage.removeItem('token-f60bd99ca58cef62dd05ce962405d279');
    }

    getToken(){
        return this.localStorage.getItem('token-f60bd99ca58cef62dd05ce962405d279');
    }

    getCurrentUser() {
        if (this.localStorage.getItem('token-f60bd99ca58cef62dd05ce962405d279') !== null) {
            const text = (this.localStorage.getItem('token-f60bd99ca58cef62dd05ce962405d279'))!;
            const token = jwt_decode.jwtDecode(text)
            return token;
        } else {
            return null;
        }
    }

    passwordResetRequest(email: string) {
        const formData = {
            email: email,
        }
        return this.postUrl('/auth/reset-password', JSON.stringify(formData)); 
    }

    changePassword(currentPwd: string, newPwd: string) {
        const formData = {
            actualPassword: currentPwd,
            newPassword: newPwd
        }
        return this.postUrl('/auth/change-password', formData)
    }

    passwordReset(email: string, code: string, password: string, confirmPassword: string): any {

        const formData = {
            code: code,
            newPassword: password
        }
        return this.postUrl('/auth/reset-password-validation', formData)
    }
}

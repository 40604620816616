import { Component, OnInit } from '@angular/core';
import { VexPopoverRef } from '@vex/components/vex-popover/vex-popover-ref';
import { MatRippleModule } from '@angular/material/core';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'vex-sidenav-user-menu',
  templateUrl: './sidenav-user-menu.component.html',
  styleUrls: ['./sidenav-user-menu.component.scss'],
  imports: [MatRippleModule, RouterLink, MatIconModule],
  standalone: true
})
export class SidenavUserMenuComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private readonly popoverRef: VexPopoverRef) {}

  ngOnInit(): void {}

  close(): void {
    /** Wait for animation to complete and then close */
    this.authService.logout();
    setTimeout(() => this.popoverRef.close(), 250);
  }
}

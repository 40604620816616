import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { Error404Component } from './layouts/errors/error-404/error-404.component';

export const appRoutes: VexRoutes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./pages/auth/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      )
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./pages/auth/reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      )
  },
  {
    path: 'administrador',
    component: LayoutComponent,
    children: [
      /*{
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },*/
      {
        path: 'geosearch',
        loadComponent: () =>
          import(
            './pages/search/geographical/geographical.component'
          ).then((m) => m.GeographicalComponent)
      },
      {
        path: 'generalsearch',
        loadComponent: () =>
              import('./pages/search/general/general.component').then(
                (m) => m.GeneralComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
      },
      {
        path: 'download',
        loadComponent: () =>
              import('./pages/download/download.component').then(
                (m) => m.DownloadComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
      },
      {
        path: 'users',
        loadComponent: () =>
              import('./pages/user/user.component').then(
                (m) => m.UserComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
      },
      {
        path: 'product',
        loadComponent: () =>
              import('./pages/product/product.component').then(
                (m) => m.ProductComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
      },
      
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'usuario',
    component: LayoutComponent,
    children: [
      /*{
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },*/
      {
        path: 'geosearch',
        loadComponent: () =>
          import(
            './pages/search/geographical/geographical.component'
          ).then((m) => m.GeographicalComponent)
      },
      {
        path: 'generalsearch',
        loadComponent: () =>
              import('./pages/search/general/general.component').then(
                (m) => m.GeneralComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
      }      
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: Error404Component
  }
];

/*
{
        path: 'apps',
        children: [
          {
            path: 'chat',
            loadChildren: () => import('./pages/apps/chat/chat.routes')
          },
          {
            path: 'mail',
            loadChildren: () => import('./pages/apps/mail/mail.routes'),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true
            }
          },
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.routes')
          },
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.routes')
          },
          {
            path: 'calendar',
            loadComponent: () =>
              import('./pages/apps/calendar/calendar.component').then(
                (m) => m.CalendarComponent
              ),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'aio-table',
            loadComponent: () =>
              import('./pages/apps/aio-table/aio-table.component').then(
                (m) => m.AioTableComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'help-center',
            loadChildren: () =>
              import('./pages/apps/help-center/help-center.routes')
          },
          {
            path: 'scrumboard',
            loadChildren: () =>
              import('./pages/apps/scrumboard/scrumboard.routes')
          },
          {
            path: 'editor',
            loadComponent: () =>
              import('./pages/apps/editor/editor.component').then(
                (m) => m.EditorComponent
              ),
            data: {
              scrollDisabled: true
            }
          }
        ]
      },

*/
